<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="barImage"
    mobile-breakpoint="960"
    app
    mini-variant-width="80"
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img :gradient="`to bottom, ${barColor}`" v-bind="props" />
    </template>
    <!-- Encabezado Titulo-->
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="text-uppercase font-weight-regular display-1">
          <span class="logo-mini">{{ $t('ct') }} </span>
          <span class="logo-normal">{{ $t('tim') }}</span>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider class="mb-1" />

    <!-- <v-list dense nav>
      <base-item-group :item="profile" />
    </v-list> -->

    <!-- Avatar y nombre en encabezado -->
    <v-list-item two-line>
      <ThumborImage :imageUrl="computedAvatar" :avatarSize="48" />
      <v-list-item-content>
        <v-list-item-title v-html="computedUser.nombre"></v-list-item-title>
        <v-list-item-subtitle v-html="computedUser.apellidos"></v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-divider class="mb-2" />

    <!-- Menú del drawer -->
    <v-list expand nav>
      <div />
      <template v-for="(item, i) in computedMenu">
        <base-item-group v-if="item.children" :key="`group-${i}`" :item="item"> </base-item-group>
        <base-item v-else :key="`item-${i}`" :item="item" />
      </template>
      <div />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// import { getValidToken } from '@/store/helpers.js'
import { mapState } from 'vuex'
// import axios from 'axios'
import ThumborImage from '@/components/general/ThumborImage.vue'

export default {
  components: {
    ThumborImage,
  },
  name: 'DashboardCoreDrawer',

  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    dashboard: [] /* para usar con Json dinamico */,

    // Items se conserva comentado como ejemplo de sintaxis para formar el array
    // actualmente se forma dinamicamente
    /* items: [
      {
        icon: 'mdi-view-dashboard',
        title: 'dashboard',
        to: '/',
      },
      {
        group: '/ventas',
        icon: 'mdi-cash-register',
        title: 'sales',
        children: [
          {
            title: 'reservations',
            icon: 'mdi-book-open-outline',
            to: 'reservas',
          },
          {
            title: 'transfers',
            icon: 'mdi-cogs',
            to: 'traslados',
          },
          {
            title: 'tours',
            icon: 'mdi-cogs',
            to: 'paseos',
          },
          {
            title: 'circuits',
            icon: 'mdi-cogs',
            to: 'circuitos',
          },
        ],
      },
      {
        group: '/store',
        icon: 'mdi-storefront-outline',
        title: 'store',
        children: [
          // Ajuste de inventarios
          {
            title: 'adjusts',
            icon: 'scale-balance',
            to: 'ajustes',
          },
          {
            icon: 'mdi-monitor-dashboard',
            title: 'console',
            to: 'console',
          },
        ],
      },
    ], */

    // Menus del drawer de administracion (Settings)
    almacen: [
      {
        icon: 'mdi-car-child-seat',
        title: 'extras',
        to: 'extras',
      },
      {
        icon: 'mdi-baby-carriage',
        title: 'extras_category',
        to: 'servicioscat',
      },
      {
        icon: 'mdi-food',
        title: 'products',
        to: 'productos',
      },
      {
        icon: 'mdi-shape',
        title: 'product_category',
        to: 'productoscat',
      },
    ],
    aplicaciones: [
      {
        icon: 'mdi-animation',
        title: 'systems',
        to: 'sistemas',
      },
      {
        icon: 'mdi-view-module',
        title: 'modules',
        to: 'modulos',
      },
      {
        icon: 'mdi-translate',
        title: 'locales',
        to: 'locales',
      },
    ],
    catalogos: [
      {
        icon: 'mdi-human-male-female', //human-male-female gender-male-female
        title: 'gender',
        to: 'generos',
      },
      {
        title: 'hotels',
        icon: 'mdi-bed',
        to: 'hoteles',
      },
      {
        icon: 'mdi-account-voice',
        title: 'languaje',
        to: 'idiomas',
      },
      {
        icon: 'mdi-flag-variant',
        title: 'countries',
        to: 'paises',
      },
      {
        icon: 'mdi-cash-100',
        title: 'exchangeRate',
        to: 'exchangeRate',
      },

      {
        icon: 'mdi-car-cog',
        title: 'vehicles_type',
        to: 'tipos',
      },
      {
        icon: 'mdi-car-estate',
        title: 'carriers',
        to: 'trasnportadoras',
      },
      {
        icon: 'mdi-map-marker-radius',
        title: 'zones',
        to: 'zonas',
      },
    ],
    tarifas: [],
    transportadora: [
      {
        icon: 'mdi-bus-multiple',
        title: 'fleets',
        to: 'flotillas',
      },
      {
        icon: 'mdi-watermark',
        title: 'brand',
        to: 'marcas',
      },
      {
        icon: 'mdi-train-car',
        title: 'model',
        to: 'modelos',
      },
    ],
    usuarios: [
      {
        icon: 'mdi-account',
        title: 'users',
        to: 'usuarios',
      },
      {
        icon: 'mdi-account-multiple',
        title: 'groups',
        to: 'grupos',
      },
      {
        icon: 'mdi-cog-transfer',
        title: 'roles',
        to: 'roles',
      },
      {
        icon: 'mdi-account-multiple',
        title: 'permisos',
        to: 'permisos',
      },
    ],
    ventas: [
      {
        icon: 'mdi-ticket-confirmation',
        title: 'tickets',
        to: 'boletosferry',
      },
      {
        icon: 'mdi-account-group',
        title: 'clients',
        to: 'clientes',
      },
      {
        icon: 'mdi-car-limousine',
        title: 'services',
        to: 'servicios',
      },
      {
        icon: 'mdi-currency-usd',
        title: 'rates',
        to: 'tarifas',
      },
      {
        icon: 'mdi-transit-transfer',
        title: 'service_type',
        to: 'traslados',
      },
    ],
  }),

  computed: {
    ...mapState(['barColor', 'barImage', 'menu']),

    /**
     * @name cumputedAvatar
     *
     * @description Obtiene el avatar del usuario, y en caso de no tener avatar, una imagen
     *
     * @return <image> imagen
     */
    computedAvatar() {
      return this.$store.state.auth.user.avatar
        ? this.$store.state.auth.user.avatar
        : '/avatars/no-image.png'
    },

    /**
     * @name computedMenu
     *
     * @description Obtiene el menu actual del store
     *
     * @return <map> mapa con los menus
     */
    computedMenu() {
      // console.log(`Menu en el store ${this.$store.state.menu}`)
      if (this.$store.state.menu === 'dashboard') return this.dashboard.map(this.mapItem)
      if (this.$store.state.menu === 'almacen') return this.almacen.map(this.mapItem)
      if (this.$store.state.menu === 'aplicaciones') return this.aplicaciones.map(this.mapItem)
      if (this.$store.state.menu === 'transportadora') return this.transportadora.map(this.mapItem)
      if (this.$store.state.menu === 'catalogos') return this.catalogos.map(this.mapItem)
      if (this.$store.state.menu === 'usuarios') return this.usuarios.map(this.mapItem)
      if (this.$store.state.menu === 'ventas') return this.ventas.map(this.mapItem)
      return this.items.map(this.mapItem)
    },

    /**
     * @name     computedUse
     *
     * @description Obtiene nombre y apellido del usuario
     *
     * @return <object> Objeto con el nombre y apellido del usuario
     */
    computedUser() {
      let nombre = this.$store.state.auth.user.nombre ? this.$store.state.auth.user.nombre : ''
      let apellidos = this.$store.state.auth.user.apellidos
        ? this.$store.state.auth.user.apellidos
        : ''
      return { nombre: nombre, apellidos: apellidos }
    },

    /**
     * getter y setter del drawer
     */
    drawer: {
      get() {
        return this.$store.state.drawer
      },
      set(val) {
        this.$store.commit('SET_DRAWER', val)
      },
    },
  },

  watch: {
    '$vuetify.breakpoint.smAndDown'(val) {
      this.$emit('update:expandOnHover', !val)
    },
  },
  /*   mounted() {
    axios.get('items.json').then((response) => (this.items2 = response.data))
   },
   */
  created() {
    //this.obtenerUsuario()
    this.userPermissions()
  },
  methods: {
    // Obtiene Usuario por su _id
    /* async obtenerUsuario() {
      this.isLoading = true
      let jwt = await getValidToken(this)
      let payload = {
        jwt: jwt,
        id: this.usuario.id,
      }
      if (jwt !== null) {
        this.$store
          .dispatch('user/fetchUsersId', payload)
          .then((response) => {
            this.profileUsr = response.data
          })
          .catch((error) => {
            console.log(error)
          })
      }
    }, */

    /**
     * @name mapItem
     */
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title),
      }
    },

    /**
     * @name mapSetting
     */
    mapSetting(setting) {
      return {
        ...setting,
        children: setting.children ? setting.children.map(this.mapSetting) : undefined,
        title: this.$t(setting.title),
      }
    },

    /**
     * @name userPermissions
     *
     * @description Obtiene los permisos del usuario
     */
    userPermissions() {
      const user = JSON.parse(localStorage.getItem('user'))
      let payload = { id: user.id }
      console.log(payload)
      this.$store
        .dispatch('user/fetchUserPermissions', payload)
        .then((response) => {
          this.dashboard = response.data
          this.dashboard.splice(0, 0, {
            icon: 'mdi-view-dashboard',
            title: 'dashboard',
            to: '/',
          })
        })
        .catch((error) => {
          console.error(error)
        })
    },
  },
}
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  &.v-navigation-drawer--mini-variant
    .v-list-item
      justify-content: flex-start !important

    .v-list-group--sub-group
      display: block !important

  .v-list-group__header.v-list-item--active:before
    opacity: .24

  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 20px

      +ltr()
      margin-right: 24px
      margin-left: 12px !important

      +rtl()
      margin-left: 24px
      margin-right: 12px !important

  .v-list--dense
    .v-list-item
      &__icon--text,
      &__icon:first-child
        margin-top: 10px

  .v-list-group--sub-group
    .v-list-item
      +ltr()
      padding-left: 8px

      +rtl()
      padding-right: 8px

    .v-list-group__header
      +ltr()
      padding-right: 0

      +rtl()
      padding-right: 0

      .v-list-item__icon--text
        margin-top: 19px
        order: 0

      .v-list-group__header__prepend-icon
        order: 2

        +ltr()
        margin-right: 8px

        +rtl()
        margin-left: 8px
</style>
